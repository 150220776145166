<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <skeleton-loading v-if="requestCalls > 0"></skeleton-loading>

                <div v-else>
                    <div class="card-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0"><b>{{ faqDetail.faq_question }}</b></h3>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div v-html="faqDetail.faq_answer" ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import faq from '@/services/setting/faq.service';

    export default {        
        data() {
            return {        
                requestCalls: 0,        
                form: {
                    add: true,
                    title: this.tt('add_faq'),
                    show: false
                },      
                faqDetail: {},
            }
        },
        mounted() {
            this.get(this.$route.params.id)
        },
        methods: {
            get(id) {
                this.requestCalls++;

                let context = this;
                Api(context, faq.show(id)).onSuccess(function(response) {
                    context.faqDetail = response.data.data[0];
                    // console.log(response.data.data[0].faq_question)
                }).onFinish(function() {
                    context.requestCalls--;
                }).call()
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.add = true;
                this.form.title = this.tt('add_faq');
                this.form.show = true;
            },
            edit(id) {
                this.form.add = false;
                this.form.title = this.tt('update_faq');
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog("Are you sure to delete this data?").then((result) => {
                    if (result.value) {
                        this.alertDialog('success', 'Successed delete data');
                    } else {
                        this.alertDialog('error', 'Failed delete data');
                    }
                })
            },
        }   
    };
</script>
<style></style>
